import { useMemo, useState } from 'react';
import Select from 'react-select';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { type Action, ActionSheet } from '../../components/ActionSheet';
import { type Option } from '../../components/common/Utilities';
import { DeleteIcon } from '../../components/icons/DeleteIcon';
import { PlayIcon } from '../../components/icons/PlayIcon';
import { Loading } from '../../components/Loading';
import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import {
  type CloudHostingDetail,
  type CloudHostingDetailsResponse,
} from '../../services/api-service/cloudHosting.api';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';

const EC2_STOPPED_CODE = 80;

async function getCloudInstanceDetails(): Promise<CloudHostingDetailsResponse> {
  const resp = await apiService.cloudHosting.getCloudInstanceDetails();
  return resp.data;
}

function AutoScaleCtrl() {
  const {
    data: enabled,
    error,
    isLoading,
    mutate,
  } = useSWR(
    '/cloud-hosting/auto-scaling/status',
    async () => {
      const resp = await apiService.cloudHosting.getAutoScalingStatus();
      return resp.data.enabled;
    },
    {
      shouldRetryOnError: false,
    }
  );

  const { trigger, isMutating } = useSWRMutation(
    `/cloud-hosting/auto-scaling/${enabled ? 'disable' : 'enable'}`,
    async () => {
      if (enabled) {
        await apiService.cloudHosting.disableAutoScaling();
      } else {
        await apiService.cloudHosting.enableAutoScaling();
      }
    }
  );

  const toggle = async () => {
    await trigger();
    mutate();
  };

  let body: JSX.Element;
  if (isLoading) {
    body = <Loading text='' />;
  } else if (error) {
    body = <div className='text-red-002 text-sms'>{err2s(error)}</div>;
  } else {
    body = (
      <button
        type='button'
        className={`w-40 h-10 ${enabled ? 'btn-delete' : 'btn-primary'}`}
        onClick={toggle}
        disabled={isMutating}
      >
        {enabled ? 'Disable' : 'Enable'}
      </button>
    );
  }

  return (
    <div className='flex items-center justify-center gap-2'>
      <label className='text-white'>Auto Scaling:</label>
      {body}
    </div>
  );
}

const poolOptions: Option<boolean | null>[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
  { label: 'Unknown', value: null },
];

function PoolFilter(props: {
  value: Option<boolean | null>;
  onChange: (value: Option<boolean | null>) => void;
}) {
  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<boolean | null>>({
        override: {
          control: {
            width: '100%',
            height: '100%',
          },
        },
      }),
    []
  );

  return (
    <div className='flex items-center gap-1'>
      <label>In the Pool:</label>
      <Select<Option<boolean | null>, false>
        value={props.value}
        options={poolOptions}
        onChange={(v) => (v ? props.onChange(v) : undefined)}
        className={`mb-0 w-50`}
        classNamePrefix='select-box-v2'
        styles={styles}
        isMulti={false}
        placeholder={'In the Pool?'}
      />
    </div>
  );
}

export const CloudHostingList = (): JSX.Element => {
  const advAdminEnabled = useFeatureQueryParam('cloud-hosting-adv-admin');
  const [poolOption, setPoolOption] = useState(poolOptions[0]);
  const { data, error, isValidating, mutate } = useSWR(
    '/cloud-hosting/get-cloud-hosting-details',
    getCloudInstanceDetails,
    {
      shouldRetryOnError: false,
    }
  );

  const allInstances = useMemo(() => {
    const instances = data?.instanceDetails.filter(
      (a) => a.InThePool === poolOption.value
    );
    if (instances) {
      instances.sort((a, b) => a.EC2.State.Code - b.EC2.State.Code);
    }
    return instances;
  }, [data?.instanceDetails, poolOption]);

  const runningInstances = useMemo(() => {
    return data?.instanceDetails.filter(
      (a) => a.EC2.State.Name === 'running' && a.InThePool === poolOption.value
    );
  }, [data?.instanceDetails, poolOption]);

  const availableInstances = useMemo(() => {
    return data?.instanceDetails.filter(
      (a) =>
        a.Instance?.state === 'available' && a.InThePool === poolOption.value
    );
  }, [data?.instanceDetails, poolOption]);

  const refresh = async () => {
    await mutate();
  };

  return (
    <div className='w-full h-full text-white px-20'>
      <div className='flex text-white items-center justify-between'>
        <p className='text-2xl font-medium ml-2'>
          Available/Running/Total: ({availableInstances?.length || 0}/
          {runningInstances?.length || 0}/{allInstances?.length || 0})
        </p>
        {advAdminEnabled && (
          <div className='flex items-center gap-2'>
            <PoolFilter value={poolOption} onChange={setPoolOption} />
            <AutoScaleCtrl />
          </div>
        )}
      </div>
      <table className='w-full mt-10'>
        <thead>
          <tr className='text-left h-14 '>
            <th>Instance ID</th>
            <th>AWS State</th>
            <th>State</th>
            {advAdminEnabled && <th>Group</th>}
            <th>Venue ID</th>
            <th>Bound At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(allInstances || []).map((instance) => (
            <CloudHostingRow
              instance={instance}
              refresh={refresh}
              advAdminEnabled={advAdminEnabled}
            />
          ))}
        </tbody>
      </table>
      <div className='w-full'>
        {!error && allInstances?.length === 0 && (
          <div className='w-full mt-32 flex items-center justify-center font-bold text-secondary'>
            No instance.
          </div>
        )}
        {isValidating && !data && <Loading />}
        {error && (
          <div className='w-full mt-32 flex items-center justify-center font-bold text-secondary'>
            Something went wrong.
          </div>
        )}
      </div>
    </div>
  );
};

const CloudHostingRow = (props: {
  instance: CloudHostingDetail;
  refresh: () => Promise<void>;
  advAdminEnabled?: boolean;
}): JSX.Element => {
  const { instance, refresh, advAdminEnabled } = props;

  const {
    state: { state: startState, error: startError },
    call: start,
  } = useLiveAsyncCall(async (instanceId: string) => {
    return await apiService.cloudHosting.startInstance(instanceId);
  });

  const {
    state: { state: enableState, error: enableError },
    call: enable,
  } = useLiveAsyncCall(async (instanceId: string) => {
    return await apiService.cloudHosting.enableInstance(instanceId);
  });

  const {
    state: { state: disableState, error: disableError },
    call: disable,
  } = useLiveAsyncCall(async (instanceId: string) => {
    return await apiService.cloudHosting.disableInstance(instanceId);
  });

  const {
    state: { state: releaseState, error: releaseError },
    call: release,
  } = useLiveAsyncCall(async (venueId: string) => {
    return await apiService.venue.releaseCloudHosting(venueId);
  });

  const onStart = useLiveCallback(async () => {
    await start(instance.EC2.InstanceId);
    await refresh();
  });

  const onEnable = useLiveCallback(async () => {
    await enable(instance.EC2.InstanceId);
    await refresh();
  });

  const onDisable = useLiveCallback(async () => {
    await disable(instance.EC2.InstanceId);
    await refresh();
  });

  const onRelease = useLiveCallback(async () => {
    if (!instance.Binding) return;
    await release(instance.Binding.venueId);
    await refresh();
  });

  const error = startError || releaseError || enableError || disableError;
  const running =
    startState.isRunning ||
    releaseState.isRunning ||
    enableState.isRunning ||
    disableState.isRunning;

  return (
    <tr className='h-10 hover:bg-lp-gray-002'>
      <td>{instance.EC2.InstanceId}</td>
      <td>
        {startState.isDone && !startError ? 'running' : instance.EC2.State.Name}
      </td>
      <td>{instance.Instance?.state}</td>
      {advAdminEnabled && <td>{instance.Instance?.group ?? 'N/A'}</td>}
      <td>{instance.Binding?.venueId}</td>
      <td>{instance.Binding?.createdAt}</td>
      <td>
        {error && (
          <div className='text-red-002 test-xl text-center'>{err2s(error)}</div>
        )}
        {running ? (
          <Loading text='' />
        ) : (
          <div className='flex flex-row-reverse items-center pr-1.5'>
            <CloudActionSheet
              instance={instance}
              onStart={onStart}
              onRelease={onRelease}
              onEnable={onEnable}
              onDisable={onDisable}
              advAdminEnabled={advAdminEnabled}
            />
          </div>
        )}
      </td>
    </tr>
  );
};

function CloudActionSheet(props: {
  instance: CloudHostingDetail;
  onStart: () => void;
  onRelease: () => void;
  onEnable: () => void;
  onDisable: () => void;
  advAdminEnabled?: boolean;
}): JSX.Element {
  const { instance, onStart, onRelease, onEnable, onDisable, advAdminEnabled } =
    props;
  const stopped = instance.EC2.State.Code === EC2_STOPPED_CODE;
  const hasBinding = !!instance.Binding;
  const inThePool = instance.InThePool;
  const actions = useMemo(() => {
    const actions: Action<string>[] = [
      {
        kind: 'button',
        key: 'start',
        icon: <PlayIcon />,
        text: 'Start',
        onClick: onStart,
        disabled: !stopped,
      },
      {
        kind: 'button',
        key: 'release',
        icon: <DeleteIcon />,
        text: 'Force Release',
        onClick: onRelease,
        className: 'text-red-002',
        disabled: !hasBinding,
      },
    ];
    if (advAdminEnabled) {
      actions.push(
        {
          kind: 'button',
          key: 'enable',
          icon: <></>,
          text: 'Add to Pool',
          onClick: onEnable,
          disabled: inThePool === true || inThePool === undefined,
        },
        {
          kind: 'button',
          key: 'disable',
          icon: <></>,
          text: 'Remove from Pool',
          onClick: onDisable,
          disabled: inThePool === false || inThePool === undefined,
        }
      );
    }
    return actions;
  }, [
    advAdminEnabled,
    hasBinding,
    inThePool,
    onDisable,
    onEnable,
    onRelease,
    onStart,
    stopped,
  ]);
  return <ActionSheet<string> actions={actions} />;
}
