import { CloudHostingList } from '../pages/Admin/CloudHosting';

function CloudHostingRoute() {
  return <CloudHostingList />;
}

export const Component = CloudHostingRoute;

export function clientLoader() {
  return null;
}
